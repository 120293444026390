"use client";
import React from "react";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  FreeMode,
} from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/free-mode";
import faculties from "../faculty/data/faculties";
import { RxArrowTopRight } from "react-icons/rx";
import Link from "next/link";


const SwiperSlider = () => {
  return (
    <div className="flex items-center justify-center flex-col">
      <Swiper
        breakpoints={{
          340: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          700: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
        }}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination, Navigation]}
        className="max-w-[90%] lg:max-w-[80%]"
      >
        {faculties.map((item) => (
          <SwiperSlide key={item.id}>
            <div className="flex flex-col justify-center items-start gap-6 mb-20 relative shadow-2xl text-black border border-green-200 rounded-xl px-6 py-8 h-[250px] w-[215px] lg:h-[380px] lg:w-[290px] overflow-hidden">
              <div className="flex flex-col gap-y-2">
                <img src={item.image} alt={item.title} className="w-14 h-14 md:w-32 md:h-32 rounded-full" />
                <h1 className="text-[12px] lg:text-lg uppercase font-semibold">{item.title} </h1>
                <p className="text-[10px] w-full lg:h-20 lg:mb-7">{item.description} </p>
              </div>
              {/* social media */}
              <div className="flex flex-row justify-center items-center gap-x-3 text-lg">
                <Link href={`${item.linkedinUrl}`} target="_blank"><img src={item.linkedinIcon} className="w-10 h-10 rounded-full border-none"/></Link>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SwiperSlider;
