"use client";

import Carousal from "@itseasy21/react-elastic-carousel";
import ReviewCard from "./ReviewCard";

const breakPoints = [
  { width: 1, itemsToShow: 1, itemsToScroll: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 850, itemsToShow: 4, itemsToScroll: 4 },
  { width: 1200, itemsToShow: 6, itemsToScroll: 6 },
];

const SuccessStories = () => {
  return (
    <Carousal breakPoints={breakPoints} showArrows={true} pagination={false} className="mt-4">
      <ReviewCard
        imgSrc="/images/reviewer4.png"
        name="Riya Mondal"
        review="Nirmalyada eto easily python ta sikhiyechile bolei amar ekhon basic concept ta clear hye geche..ar Java sikhte osubidha hocchena ekdom e..plus recording gulo o diyechile..jokhon e kono osubidha hoy kono topic e,sei video gulo dekhe ni."
      />
      <ReviewCard
        imgSrc="/images/reviewer10.jpg"
        name="Shatabdi Banerjee"
        review="Content writing er class kore amr vison valo legeche. Puro bisoy ta Bangla e bojhanor jonno aro vlo jeno bujhte subidhe hoeche. Sayani ma'am ashadharon vabe bojhan ...onar class korte khub e valo lage.recently onar e internship er team e join korechi and kaj o sikhchi.  ❤️❤️"
      />
      <ReviewCard
        imgSrc="/images/reviewer7.png"
        name="Ipsita Mukherjee"
        review="Tushar Sir's class on Full Stack Web Development has been incredibly beneficial for me. He enhanced my skills and confidence in Full Stack Web Development."
      />
      <ReviewCard
        imgSrc="/images/reviewer5.png"
        name="Sudipto Ghosh"
        review="এক দাদার কাছে vidyastur ব্যাপারে জানতে পেরে নির্মাল্যদাকে ফোন করেছিলাম বড্ড puzzle ছিলাম কোর্সটা করার আগে। ফরিদ স্যার খুবই ভালো করে সবটা বুঝিয়ে ক্লাস করাতেন লাস্ট ক্লাস শেষের পরদিনই জব হয়ে গেলো...আগে জানিনা কি হবে না হবে তবে thanks to nirmalya da❤️"
      />
      <ReviewCard
        imgSrc="/images/reviewer6.png"
        name="Anirban Mukherjee"
        review="I joined vidyastu to take training for MERN stack . My teacher Farid sir is highly skilled and a great teacher. I got a job at Msqube technology solutions pvt ltd.."
      />
      <ReviewCard
        imgSrc="/images/reviewer8.jpg"
        name="Dwipen Dutta"
        review="I decided to enroll at Fullstack Development after a few classes my experience was relatively good, the faculty teachers were very knowledgeable, the entire @vidyastu community is very helpful and create a great ambiance to learn thanks Vidyastu"
      />
    </Carousal>
  );
};

export default SuccessStories;
