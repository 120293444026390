import(/* webpackMode: "eager" */ "/opt/render/project/src/components/common/ContactForm.jsx");
import(/* webpackMode: "eager" */ "/opt/render/project/src/components/common/LeadFormHome.jsx");
import(/* webpackMode: "eager" */ "/opt/render/project/src/components/common/whatsapp.css");
import(/* webpackMode: "eager" */ "/opt/render/project/src/components/home/cards/CardReveal.jsx");
import(/* webpackMode: "eager" */ "/opt/render/project/src/components/home/event/EventSection.jsx");
import(/* webpackMode: "eager" */ "/opt/render/project/src/components/home/free-courses/FreeCourses.jsx");
import(/* webpackMode: "eager" */ "/opt/render/project/src/components/home/hero-slider/Slider.jsx");
import(/* webpackMode: "eager" */ "/opt/render/project/src/components/home/news/textcolor.css");
import(/* webpackMode: "eager" */ "/opt/render/project/src/components/home/our-courses/OurCourseCards.jsx");
import(/* webpackMode: "eager" */ "/opt/render/project/src/components/home/success-stories/SuccessStories.jsx");
import(/* webpackMode: "eager" */ "/opt/render/project/src/components/home/test-slider/SwiperSlider.jsx");
import(/* webpackMode: "eager" */ "/opt/render/project/src/components/ui/InfiniteMovingCard.jsx");
import(/* webpackMode: "eager" */ "/opt/render/project/src/components/ui/TypeWriterEffect.jsx");
import(/* webpackMode: "eager" */ "/opt/render/project/src/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/opt/render/project/src/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/opt/render/project/src/node_modules/next/dist/client/script.js")